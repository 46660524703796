import { red_primary, blue_primary } from "../styles/colors"
import styled from 'styled-components';

const StyledTitle = styled.h2`
    font-size: 1.8em;
    font-weight: 700;
    color: ${red_primary};

    @media (max-width: 1280px) {
        font-size: 1.6em;
    }

    @media (max-width: 1024px) {
        font-size: 1.4em;
    }

    @media (max-width: 768px) {
        font-size: 1.2em;
    }
    
    @media (max-width: 576px) {
        font-size: 1em;
    }

    @media (max-width: 320px) {
        font-size: 0.8em;
    }
`

const StyledContentText = styled.div`
    font-size: 1.2em;
    font-weight: 400;

    @media (max-width: 1280px) {
        font-size: 1em;
    }

    @media (max-width: 1024px) {
        font-size: 1em;
    }

    @media (max-width: 768px) {
        font-size: 1.2em;
    }
    
    @media (max-width: 576px) {
        font-size: 1em;
    }

    @media (max-width: 320px) {
        font-size: 1em;
    }
`


export const languageProgramsContent = {
    title: <StyledTitle>Chương trình học ngôn ngữ</StyledTitle>,
    description: (
        <div>
            <StyledContentText>Canada là quốc gia đa văn hóa với hai ngôn ngữ chính thức là tiếng Anh và tiếng Pháp. Do đó, chương trình học ngôn ngữ tại Canada cung cấp đa dạng lựa chọn cho sinh viên quốc tế, giúp bạn trau dồi khả năng ngoại ngữ trong môi trường học tập lý tưởng.</StyledContentText>
            <br />
            <br />

            <StyledContentText><h3></h3><b style={{ color: red_primary }}>Chất lượng giáo dục cao:</b> Các trường ngôn ngữ tại Canada đều được kiểm định và cấp phép bởi chính phủ, đảm bảo chất lượng giảng dạy và chương trình học tiên tiến.
            Đa dạng lựa chọn: Sinh viên có thể lựa chọn học tiếng Anh, tiếng Pháp hoặc cả hai, với nhiều cấp độ từ cơ bản đến nâng cao.
            Học tập trong môi trường thực tế: Canada là môi trường lý tưởng để học tiếng Anh hoặc tiếng Pháp vì hai ngôn ngữ này được sử dụng rộng rãi trong đời sống hàng ngày.
            Cơ hội nghề nghiệp: Nâng cao khả năng ngoại ngữ giúp bạn có thêm nhiều cơ hội học tập và làm việc tại Canada và trên thế giới.
            </StyledContentText>
            <br />
            <StyledContentText><i><u>Loại hình chương trình:</u></i></StyledContentText>
            <br />
            <StyledContentText style={{ marginLeft: "2rem" }}>
            <ul className="red-bullet">
                <li>
                    <b >Chương trình tiếng Anh:</b> Dành cho sinh viên muốn học tiếng Anh từ cơ bản đến nâng cao, hoặc chuẩn bị cho các kỳ thi năng lực tiếng Anh như IELTS, TOEFL.
                </li>
                <li>
                    <b>Chương trình tiếng Pháp:</b> Dành cho sinh viên muốn học tiếng Pháp từ cơ bản đến nâng cao, hoặc chuẩn bị cho các kỳ thi năng lực tiếng Pháp như DELF, DALF.
                </li>
                <li>
                    <b>Chương trình song ngữ:</b> Kết hợp học cả tiếng Anh và tiếng Pháp, giúp sinh viên thành thạo cả hai ngôn ngữ.
                </li>
            </ul>
            </StyledContentText>
            <br />
            <StyledContentText>
            <i><u>Học phí:</u></i>
            <br />
            Học phí cho chương trình học ngôn ngữ tại Canada phụ thuộc vào nhiều yếu tố như loại hình chương trình, thời gian học tập, vị trí trường học, v.v. Tuy nhiên, nhìn chung học phí khá hợp lý so với chất lượng giáo dục và cơ hội mà chương trình mang lại.
            </StyledContentText>
        </div>
    )

}

export const highSchoolProgramsContent = {
    title: <StyledTitle>Chương trình trung học</StyledTitle>,
    description: (
        <div>
            <StyledContentText>Canada nổi tiếng với hệ thống giáo dục trung học chất lượng cao, cung cấp cho học sinh môi trường học tập tốt nhất để phát triển toàn diện. Chương trình trung học tại Canada không chỉ tập trung vào kiến thức học thuật mà còn chú trọng phát triển kỹ năng cá nhân và xã hội.</StyledContentText>
            <br />
            <br />

            <StyledContentText><b style={{ color: red_primary }}>Chất lượng giáo dục vượt trội:</b> Các trường trung học tại Canada được trang bị cơ sở vật chất hiện đại và giáo viên giàu kinh nghiệm, đảm bảo chất lượng giảng dạy cao.
            Đa dạng chương trình học: Học sinh có thể lựa chọn nhiều chương trình học khác nhau, bao gồm chương trình quốc tế, chương trình nâng cao (AP), và chương trình song ngữ.
            Môi trường học tập an toàn: Canada là một trong những quốc gia an toàn nhất thế giới, tạo điều kiện lý tưởng cho học sinh quốc tế học tập và sinh hoạt.
            Cơ hội phát triển kỹ năng mềm: Các hoạt động ngoại khóa đa dạng giúp học sinh phát triển các kỹ năng mềm quan trọng như lãnh đạo, làm việc nhóm, và quản lý thời gian.</StyledContentText>
            <br />
            <StyledContentText><h3><i><u>Loại hình chương trình:</u></i></h3></StyledContentText>
            <br />
            <StyledContentText style={{ marginLeft: "2rem" }}>
            <ul className="red-bullet">
                <li>
                    <b>Chương trình phổ thông:</b> Cung cấp kiến thức cơ bản và nâng cao cho học sinh từ lớp 9 đến lớp 12, chuẩn bị cho các kỳ thi vào đại học.
                </li>
                <li>
                    <b>Chương trình quốc tế:</b> Dành cho học sinh muốn theo đuổi bằng tú tài quốc tế (IB) hoặc các chương trình tương đương, giúp học sinh có cơ hội học tập và làm việc tại nhiều quốc gia trên thế giới.
                </li>
                <li>
                    <b>Chương trình nâng cao (AP):</b> Cung cấp các khóa học cấp đại học cho học sinh trung học, giúp học sinh tích lũy tín chỉ đại học ngay từ khi còn học trung học.
                </li>
                <li>
                    <b>Chương trình song ngữ:</b> Giúp học sinh thành thạo cả tiếng Anh và tiếng Pháp, mở rộng cơ hội học tập và làm việc trong môi trường đa ngôn ngữ.
                </li>
            </ul>
            </StyledContentText>
            <br />
            <StyledContentText>
            <i><u>Học phí:</u></i>
            <br />
            Học phí cho chương trình trung học tại Canada phụ thuộc vào nhiều yếu tố như loại hình trường học, vị trí địa lý, và chương trình học. Tuy nhiên, học phí trung học tại Canada được đánh giá là hợp lý so với chất lượng giáo dục và các cơ hội phát triển mà chương trình mang lại.
            </StyledContentText>
        </div>
    )
}

export const collegeUniversityProgramsContent = {
    title: <StyledTitle>Chương trình cao đẳng và đại học</StyledTitle>,
    description: (
        <div>
            <StyledContentText>Các chương trình cao đẳng và đại học tại Canada nổi tiếng với chất lượng giáo dục hàng đầu, cơ hội nghiên cứu và việc làm rộng mở. Sinh viên quốc tế lựa chọn Canada không chỉ vì bằng cấp được công nhận toàn cầu mà còn vì môi trường sống an toàn và đa văn hóa.</StyledContentText>
            <br />
            <br />

            <StyledContentText><b style={{ color: red_primary }}>Chất lượng giáo dục đỉnh cao:</b> Các trường cao đẳng và đại học Canada luôn nằm trong top các bảng xếp hạng quốc tế về chất lượng giảng dạy và nghiên cứu.
            Đa dạng ngành học: Sinh viên có thể lựa chọn từ hàng trăm ngành học khác nhau, từ kỹ thuật, khoa học, y học đến nghệ thuật, kinh tế và xã hội học.
            Cơ hội việc làm: Canada cung cấp nhiều cơ hội thực tập và việc làm cho sinh viên ngay trong quá trình học, giúp tích lũy kinh nghiệm thực tiễn.
            Môi trường sống lý tưởng: Canada nổi tiếng với xã hội an toàn, môi trường sống thân thiện và đa dạng văn hóa, là nơi lý tưởng để học tập và sinh sống.</StyledContentText>
            <br />
            <StyledContentText><h3><i><u>Loại hình chương trình:</u></i></h3></StyledContentText>
            <br />
            <StyledContentText style={{ marginLeft: "2rem" }}>
            <ul className="red-bullet">
                <li>
                    <b>Chương trình cao đẳng:</b> Tập trung vào đào tạo thực hành, giúp sinh viên sẵn sàng làm việc ngay sau khi tốt nghiệp với các kỹ năng nghề nghiệp chuyên sâu.
                </li>
                <li>
                    <b>Chương trình đại học:</b> Cung cấp kiến thức chuyên sâu trong nhiều lĩnh vực, giúp sinh viên phát triển toàn diện và chuẩn bị cho các bậc học cao hơn.
                </li>
                <li>
                    <b>Chương trình sau đại học:</b> Bao gồm các khóa học thạc sĩ và tiến sĩ, tập trung vào nghiên cứu và phát triển chuyên môn, mở ra cơ hội nghề nghiệp cao cấp.
                </li>
                <li>
                    <b>Chương trình liên kết:</b> Cho phép sinh viên chuyển tiếp từ cao đẳng lên đại học, tiết kiệm thời gian và chi phí học tập.
                </li>
            </ul>
            </StyledContentText>
            <br />
            <StyledContentText>
            <i><u>Học phí:</u></i>
            <br />
            Học phí cho các chương trình cao đẳng và đại học tại Canada có sự chênh lệch tùy thuộc vào loại hình trường học, vị trí và chương trình học. Tuy nhiên, so với các quốc gia khác, học phí tại Canada được đánh giá là hợp lý, đặc biệt khi xét đến chất lượng giáo dục và cơ hội phát triển mà các chương trình mang lại.
            </StyledContentText>
        </div>
    )
}

export const whyGoCANContent = {
    title: <StyledTitle>Tại sao chọn goCAN để du học cùng con của bạn?</StyledTitle>,
    description: (
        <div>
    <StyledContentText>goCAN được thành lập bởi cựu du học sinh Canada - người hiểu rõ những khó khăn mà học sinh trải qua trong quá trình du học, vậy nên goCAN mang không chỉ là đơn vị tư vấn du học uy tín mà còn là người bạn đồng hành tin cậy, luôn sát cánh cùng học sinh và phụ huynh trong hành trình hiện thực hóa ước mơ du học.
    <br />
    <br />
    <ul className="red-bullet">
        <li>
            <b>Đồng hành tận tâm:</b> goCAN luôn đồng hành cùng học sinh và phụ huynh từ những bước đầu tiên, từ việc lựa chọn trường học phù hợp đến việc chuẩn bị hồ sơ xin học bổng, visa, và hỗ trợ thích nghi với môi trường du học.
        </li>
        <li>
            <b>Thấu hiểu:</b> goCAN thấu hiểu những lo lắng và khó khăn của học sinh và phụ huynh khi du học xa nhà. Do đó, goCAN luôn nỗ lực mang đến trải nghiệm du học tốt nhất cho học sinh, giúp các em tự tin hòa nhập và thành công trên con đường học vấn.
        </li>
        <li>
            <b>Cầu nối tin cậy:</b> goCAN là cầu nối tin cậy giữa phụ huynh và nhà trường, giúp phụ huynh luôn cập nhật tình hình học tập và sinh hoạt của con em mình tại nước ngoài.
        </li>
    </ul>
    </StyledContentText>
</div>

    )
}