import React from "react";
import { Card } from "antd";
import gocan_logo from "../../assets/gocan_logo.png";
import { red_primary } from "../../styles/colors";
const { Meta } = Card;

const HighLightCard = (props) => (
  <Card
    hoverable
    style={{
      width: "45%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2em 0.5em 0.5em 0.5em",
      marginTop: "1em",
      borderColor: "rgba(0, 0, 0, 0.2)",
    }}
    cover={
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img alt="example" src={gocan_logo} style={{ width: "30%" }} />
      </div>
    }
  >
    <Meta
      style={{
        maxHeight: "20em",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,
      }}
      title={
        <h2 style={{ fontSize: "2em", textAlign: "center", color: red_primary, lineHeight: "normal" }}>
          {props.title}
        </h2>
      }
      description={
        <div style={{ display: "flex", textAlign: "center", fontSize: "1.3em", color: "#302F2F", lineHeight: "normal" }}>
          {props.description}
        </div>
      }
    />
  </Card>
);

export default HighLightCard;
