import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Select } from 'antd';
import gocan_open from '../assets/gocan_open.png';
const { Option } = Select;

const SurveyPopup = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  // const [inputPlayerPhone, setInputPlayerPhone] = useState('');

  // const handlePhoneInputChange = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[0-9\b]+$/; // Allow only numbers and backspace
  //   if (value === '' || regex.test(value)) {
  //     setInputPlayerPhone(value);
  //     form.setFieldsValue({ phoneNumber: value });
  //   }
  // };

  const isVietnamesePhoneNumber = (phoneNumber) => {
    const regex = /(?:\+84|0084|0)[235789][0-9]{1,2}[0-9]{7}(?:[^\d]+|$)/g;
    return regex.test(phoneNumber);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    form.setFieldsValue({ phone: value });
  };

  useEffect(() => {
    // Load saved data from localStorage
    const savedData = JSON.parse(localStorage.getItem('surveyData'));
    if (savedData) {
      setFormData(savedData);
      form.setFieldsValue(savedData);
    }
  }, [form]);

  const handleFinish = (values) => {
    // Save form data to localStorage
    localStorage.setItem('surveyData', JSON.stringify(values));
    // check if phone number is valid, if not, show alert
    if (!isVietnamesePhoneNumber(values.phone)) {
      alert('Please enter a valid Vietnamese phone number!');
      return;
    }
    
    onSubmit(values);
  };

  const handleCancel = () => {
    const values = form.getFieldsValue();
    localStorage.setItem('surveyData', JSON.stringify(values));
    onCancel();
  };

  return (
    <Modal
      open={visible}
      title="Hãy để goCAN tư vấn cho bạn!"
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Đăng ký tư vấn
        </Button>,
      ]}
    >
      {/* <img src={gocan_open} alt="gocan" style={{ width: '100%', marginBottom: 20 }} /> */}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <Form.Item
          name="name"
          label="Tên của bạn:"
          rules={[{ required: true, message: 'Hãy nhập tên của bạn!' }]}
        >
          <Input placeholder="Nhập tên của bạn" />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Giới tính"
          rules={[{ required: true, message: 'Hãy chọn giới tính!' }]}
        >
          <Select placeholder="...">
            <Option value="male">Nam</Option>
            <Option value="female">Nữ</Option>
            <Option value="other">Khác</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="grade"
          label="Lớp"
          rules={[{ required: true, message: 'Bạn đang học lớp mấy?' }]}
        >
          <Select placeholder="...">
            <Option value="8">8</Option>
            <Option value="9">9</Option>
            <Option value="10">10</Option>
            <Option value="11">11</Option>
            <Option value="12">12</Option>
            <Option value="12>">Trung cấp/Cao đẳng/Đại học</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="service"
          label="Bạn muốn:"
          rules={[{ required: true, message: 'Hãy chọn dịch vụ bạn muốn' }]}
        >
          <Select placeholder="...">
            <Option value="duhoc">Du học</Option>
            <Option value="laodong">Lao động</Option>
            <Option value="dinhcu">Định cư</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[{ required: true, message: 'Please enter your phone number!' }]}
        >
          <Input
            placeholder="Enter phone number"
            type="text" value={form.getFieldValue('phone')}
            onChange={handlePhoneNumberChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SurveyPopup;
