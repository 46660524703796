import React from 'react';
import './LandingPage.css'; // Import your CSS file
import TourForm from './TourForm';
import background_img from '../assets/background_image.jpg'; // Import your image file
import gocan_text from '../assets/gocan_text.svg'; // Import your image file
import gocan_logo from '../assets/gocan_logo.svg'; // Import your image file
import { Cascader, InputNumber, Select, Space, Button, Popover } from 'antd';
import { useState } from 'react';

const primary_red = "#E31E25"
const footer_text_style = {
  color: primary_red, fontSize: '1em', width: "100%", fontFamily: "Open Sans, sans-serif", fontWeight: "600", textAlign: "center", TextDecoder: "italic",
  // margin: "1vh 0vw"
}

function LandingPage() {

  const [phoneNumber, setPhoneNumber] = useState('');

  const handleClick = async () => {
    try {
      if (phoneNumber === '') {
        //show pop-up message
        alert('Vui lòng nhập số điện thoại');
        return;
      }
      const timestamp = new Date().toISOString();
      const data = {
        phone_number: phoneNumber,
        time: timestamp
      };
      console.log('Data to be sent:', data);

      // const response = await fetch('https://s3w0lsaet8.execute-api.ap-southeast-1.amazonaws.com/prod/append_to_file', {
      // const response = await fetch('https://0e45fzukt2.execute-api.ap-southeast-1.amazonaws.com/', {
      const response = await fetch('https://0e45fzukt2.execute-api.ap-southeast-1.amazonaws.com/append_to_file', {

        // mode: 'no-cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Failed to make POST request');
      }

      console.log('POST request successful!');
      alert('Đã ghi nhận số điện thoại của bạn. Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.');
      setPhoneNumber('');
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };

  const handleInputChange = (event) => {
    if (event === null) {
      setPhoneNumber('');
      return;
    }
    setPhoneNumber(event.toString());
  };

  return (
    <div className="landing-page"
      style={{
        width: '100%',
        height: '100vh', // 100% of viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // backgroundColor: "rgba(0, 0, 0, 0.3)",

        //horizontal alignment
      }}>
      <div className="landing-page-content"
      // style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}
      >
        <div className="landing-page-header" style={{ textAlign: 'center', height: "10vh", justifyContent: "center", display: "flex", flexDirection: "column" }}>
          <img src={gocan_text} alt="brand-name-only-text" style={{ height: "5vh", objectFit: "contain", paddingTop: "1vh" }} />
        </div>
        <img
          src={background_img} // Replace with your image path
          alt="Landing page background"
          className="background-image"
          style={{ width: '100%', height: 'auto', maxWidth: '100%', objectFit: 'contain' }}
        />
        <div className="text-block" style={{
          position: 'absolute', top: '10vh', left: '50%',
          width: "80%",
          // backgroundColor: "rgba(0, 0, 0, 0.3)",
          transform: 'translate(-50%, -0%)'
        }}>
          <div style={{
            color: 'white', fontSize: '2em', width: "100%", fontFamily: "Open Sans, sans-serif", fontWeight: "800", marginBottom: "6vh", marginTop: "7vh"
          }}>
            DU HỌC CANADA
          </div>
          <div style={{
            color: 'white', fontSize: '1em',
            // width: "100%", 
            fontFamily: "Open Sans, sans-serif",
            margin: "6vh 0vw 3vh 0vw",
            // backgroundColor: "rgba(255, 255, 255, 0.3)", 
            padding: "1vh", borderRadius: "1vh",
            fontWeight: "500", textAlign: "center",
            maxHeight: "20vh",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // whiteSpace: "nowrap"
          }}>
            Canada - điểm đến du học lý tưởng với nền giáo dục chất lượng hàng đầu, môi trường sống an toàn và đa văn hóa, cùng cơ hội việc làm rộng mở
          </div>

          <div style={{
            color: 'white', fontSize: '1em',
            // width: "100%", 
            fontFamily: "Open Sans, sans-serif",
            margin: "0 0vw",
            backgroundColor: "rgba(255, 255, 255, 0.3)", padding: "1vh", borderRadius: "1vh",
            fontWeight: "500", textAlign: "justify"
          }}>
            Cùng GoCAN thực hiện giấc mơ du học Canada!
          </div>

          <div style={{
            color: 'white', fontSize: '1em', width: "100%", fontFamily: "Open Sans, sans-serif", fontWeight: "500", textAlign: "justify",
            margin: '3vh 0vw 0vh 0vw'
          }}>
            Hãy để lại số điện thoại
          </div>

          <div style={{
            width: "100%",
            // backgroundColor: "rgba(255, 255, 255, 0.3)",
            alignItems: "center",
            justifySelf: "center",
          }}>

            <InputNumber placeholder="Số điện thoại"
              type="number"
              onChange={handleInputChange}
              value={phoneNumber}
              addonBefore={<span style={{ color: "white" }}>SĐT:</span>}
              style={{
                width: "100%",
                keyboardType: "numeric",
                margin: '1vh 0vw 0vh 0vw'
              }}
            />
            <Button type="primary" style={{ fontSize: "1.2rem", width: "100%", height: "auto", marginTop: "2vh", backgroundColor: "#E31E25", boxShadow: "1px 1px 10px 1px grey" }} onClick={handleClick}
            >NHẬN TƯ VẤN MIỄN PHÍ</Button>
          </div>
        </div>
        <div>
          <img src={gocan_logo} alt="brand-logo" style={{ height: "7vh", objectFit: "contain", paddingTop: "1vh", opacity: 0.3 }} />
          {/* <div style={footer_text_style}>Tư vấn trường phù hợp</div>
          <div style={footer_text_style}>Hỗ trợ tìm kiếm nhà</div>
          <div style={footer_text_style}>Chia sẻ kinh nghiệm</div> */}
          <div style={{ ...footer_text_style, opacity: 0.3 }}>Luôn đồng hành cùng bạn</div>
          <div style={footer_text_style}></div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;