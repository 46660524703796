import { Cascader, InputNumber, Select, Space, Button, Popover } from 'antd';
//import table from antd
import { useState } from 'react';
import { Table } from 'antd';
function Leads() {
    const [leadsData, setLeadsData] = useState([]);
    const columns = [
        {
            title: 'phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'date',
            dataIndex: 'date',
            key: 'date',
        },
    ];
    const handleClick = async () => {
        try {
            const response = await fetch('https://0e45fzukt2.execute-api.ap-southeast-1.amazonaws.com/leads');
            console.log('Response:', response)
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            //reverse data to show the latest data first
            data.reverse();
            console.log('Data:', data);
            setLeadsData(data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    
    return (
        <div>
            <Button onClick={handleClick}>Fetch data</Button>
            <Table dataSource={leadsData} columns={columns} />
        </div>
    );
}

export default Leads;