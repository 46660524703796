import React from "react";
import { Card } from "antd";
import gocan_logo from "../../assets/gocan_logo.png";
import { red_primary } from "../../styles/colors";
const { Meta } = Card;

const CoreServiceCard = (props) => (
  <Card
    size="small"
    hoverable
    onClick={props.onClick}
    style={{
      width: "90%",
      maxWidth: "400px", // Add a max width for responsiveness
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "1em",
      marginTop: "1em",
      borderColor: "rgba(0, 0, 0, 0.2)",
      boxSizing: "border-box" // Ensure padding is included in the width calculation
    }}
    cover={
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img alt="logo chương trình du học Canada" src={gocan_logo} style={{ width: "50%" }} /> {/* Adjusted the logo size */}
      </div>
    }
  >
    {/* <Meta */}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        textOverflow: "ellipsis", // Handle overflow text with ellipsis
        wordWrap: "break-word" // Wrap long words
      }}
    >
      {/* title={ */}
        <h2 style={{ fontSize: "1.2em", textAlign: "center", color: red_primary, lineHeight: "normal", wordWrap: 'break-word' }}>
          {props.title}
        </h2>
      {/* } */}
      {/* description={ */}
        <div style={{ textAlign: "justify", fontSize: "1em", color: "#302F2F", lineHeight: "normal", wordWrap: 'break-word' }}>
          {props.description}
        </div>
      {/* } */}
      </div>
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <h3 style={{ color: red_primary, fontStyle: "oblique", cursor: "pointer" }} onClick={props.onClick}>
        Xem thêm...
      </h3>
    </div>
  </Card>
);

export default CoreServiceCard;
