import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Drawer, Affix } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import { MenuOutlined } from '@ant-design/icons';

import logo_img from '../assets/homepage_logo.png';
import banner_img from '../assets/banner_with_title.jpg';
import ceo_testimonial_bgr from '../assets/ceo_testimonial_bgr.jpg';
import footer_bgr from '../assets/footer_bgr.jpg';
import ceo_avt from '../assets/ceo_avt.png';
import company_name from '../assets/gocan_white.svg';
import coreServiceContentImg from '../assets/core_services_content_img.png';
import highSchoolServiceContentImg from '../assets/highschool_program.png';
import languageServiceContentImg from '../assets/language_program.png';
import universityServiceContentImg from '../assets/university_program.png';
import { languageProgramsContent, highSchoolProgramsContent, collegeUniversityProgramsContent, whyGoCANContent } from '../assets/content_src';
import { red_primary, blue_primary } from "../styles/colors";
import CoreServiceCard from './components/core_service_card';
import HighLightCard from './components/highlight_card';

import zalo_icon from '../assets/zalo-icon.png';
import Survey from './survey_new_user';
import { REGISTER_LAMBDA_URL } from '../config';
const { Header, Footer, Content } = Layout;
const apiKey = process.env.REACT_APP_API_KEY;

const useScrollToHash = () => {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);
};

const Section = ({ id, children }) => {
  const { ref } = useInView({ threshold: 0.4 });
  return (
    <div id={id} ref={ref}>
      {children}
    </div>
  );
};

const StyledLink = styled.a`
  margin-left: 1rem;
  color: ${props => props.color || blue_primary};
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  line-height: normal;
  margin-right: 1rem;
  
  &:hover {
    color: red;
  }

  @media (min-width: 768px) {
    margin-left: 2rem;
    font-size: 1.3rem;
    margin-right: 1rem;
  }
`;

const ContentStyle = styled(Content)`
  text-align: center;
  min-height: 120px;
  line-height: 120px;
  color: #fff;
  background-color: #fff;
`;

const LayoutStyle = styled(Layout)`
  border-radius: 8px;
  overflow: hidden;
  width: 100vw;
  max-width: 100vw;
`;

const HeaderStyle = styled(Header)`
  text-align: center;
  color: #fff;
  height: 8.5em;
  padding: 0 3%;
  line-height: 64px;
  background-color: #fff;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const FooterStyle = styled(Footer)`
  width: 100%;
  height: fit-content;
  background-image: url(${footer_bgr});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    height: 8em;
  }
`;

const BannerButton = styled(Button)`
  position: absolute;
  height: fit-content;
  top: 75%;
  left: 25%;
  transform: translate(-50%, -50%);
  font-size: 1em;
  padding: 0.3rem 0.7rem;
  line-height: normal;
  border-radius: 0.8rem;
  background: linear-gradient(to right, #E31E25, #FF676C);
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 0.75rem #333;

  @media (max-width: 500px) {
    font-size: 1em;
    padding: 0.3rem 0.7rem;
    left: 20%;
  }
  @media (min-width: 500px) {
    font-size: 1.2em;
    padding: 0.5rem 1rem;
    left: 20%;
  }
  @media (min-width: 768px) {
    font-size: 1.2em;
    padding: 0.5rem 1rem;
    left: 20%;
  }
  @media (min-width: 920px) {
    font-size: 1.8em;
    padding: 1rem 1.5rem;
    left: 20%;
  }
  @media (min-width: 1280px) {
    font-size: 2.3em;
    padding: 1.2rem 1.7rem;
  }
  @media (min-width: 1800px) {
    font-size: 3em;
  }
`;

const CeoTestimonialBgr = styled.div`
  width: 100vw;
  background-image: url(${ceo_testimonial_bgr});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    height: 30em;
  }
`;

const CeoTestimonialText = styled.div`
  color: #fff;
  font-size: 1rem;
  line-height: normal;
  text-align: justify;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
  @media (min-width: 1080px) {
    font-size: 1.2rem;
  }
  @media (min-width: 1280px) {
    font-size: 1.3rem;
  }
  @media (min-width: 1800px) {
    font-size: 1.5rem;
  }
`;

const MoveToSection = (section_id) => {
  const sectionElement = document.getElementById(section_id);
  sectionElement.scrollIntoView({ behavior: 'smooth' });
};


const HomePageHeader = () => {
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const showDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  return (
    <HeaderStyle>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <a href="https://gocan.vn">
        <img src={logo_img} alt="goCAN homepage logo" style={{ height: "6em" }}/>
      </a>
      </div>
      {isMobile ? (
        <div style={{ marginTop: "1em" }}>
          <Button type="primary" onClick={showDrawer}>
            <MenuOutlined />
          </Button>
          <Drawer
            title="Menu"
            placement="right"
            closable
            onClose={closeDrawer}
            open={visible}
          >
            <StyledLink onClick={closeDrawer}>Trang chủ</StyledLink>
            <StyledLink onClick={closeDrawer}>Chương trình học</StyledLink>
            <StyledLink onClick={closeDrawer}>Du học Canada</StyledLink>
            <StyledLink onClick={closeDrawer}>Chia sẻ kinh nghiệm</StyledLink>
            <StyledLink onClick={() => { closeDrawer(); MoveToSection('AboutUs'); }}>Về chúng tôi</StyledLink>
          </Drawer>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', width: "60vw", justifyContent: "right" }}>
          <StyledLink color={red_primary}>Trang chủ</StyledLink>
          <StyledLink>Chương trình học</StyledLink>
          <StyledLink>Du học Canada</StyledLink>
          <StyledLink>Chia sẻ kinh nghiệm</StyledLink>
          <StyledLink onClick={() => MoveToSection('AboutUs')}>Về chúng tôi</StyledLink>
        </div>
      )}
    </HeaderStyle>
  );
};

const HomePageFooter = () => (
  <FooterStyle>
    <Row style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1em 3%", flexWrap: "wrap" }}>
      <Col xs={24} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", padding: "1em" }}>
        <div style={{ color: "white", fontSize: "2rem", fontWeight: "bold" }}>Thông tin liên hệ</div>
      </Col>
      <Col xs={24} md={14} style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "start", padding: "1em" }}>
        <div style={{ color: "white", fontSize: "1rem", textAlign: "start", marginTop: "0.5em", wordWrap: 'break-word' }}>
          <a style={{ color: "white", fontSize: "1rem", textAlign: "start", marginTop: "0.5em", wordWrap: 'break-word' }} href="https://maps.app.goo.gl/WAgrPP1d4NMtZ82f6" target="_blank">
            <b>Địa chỉ:</b> Số 11 - Đường Cách Mạng Tháng 8 - Phường Lái Thiêu -  Thành phố Thuận An - Tỉnh Bình Dương
          </a>
        </div>
        <div style={{ color: "white", fontSize: "1rem", textAlign: "start", marginTop: "0.5em", wordWrap: 'break-word' }}>
          <a style={{ color: "white", fontSize: "1rem", textAlign: "start", marginTop: "0.5em", wordWrap: 'break-word' }} href="tel:0937122088">
            <b>Số điện thoại:</b> 0937-122-088
          </a>
        </div>
        <div style={{ color: "white", fontSize: "1rem", textAlign: "start", marginTop: "0.5em", wordWrap: 'break-word' }}>
          <address>
            <a style={{ color: "white", fontSize: "1rem", textAlign: "start", marginTop: "0.5em", wordWrap: 'break-word' }} href="mailto:duhoc@gocan.vn">
              <b>Email:</b> duhoc@gocan.vn
            </a>
          </address>
        </div>
      </Col>
    </Row>
  </FooterStyle>
);

const AffixSocialMedia = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const icon_width = isMobile ? "3em" : "4em";
  return (
    <Affix offsetBottom={10} style={{ position: 'fixed', bottom: 10, right: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <a href="https://zalo.me/0937122088" target="_blank" rel="noreferrer">
          <img src={zalo_icon} alt="zalo_icon" style={{ width: icon_width, margin: "0.5em" }} />
        </a>
      </div>
    </Affix>
  );
};

const HomePageContent = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseContent, setSelectedCourseContent] = useState(highSchoolProgramsContent);
  const [coreServiceCoverImage, setCoreServiceCoverImage] = useState(universityServiceContentImg);

  useEffect(() => {
    if (selectedCourse) {
      MoveToSection("ServicesInformation");
    }
  }, [selectedCourse]);

  const handleCourseClick = (course) => {
    setSelectedCourse(null);
    setTimeout(() => {
      setSelectedCourse(course);
      switch (course) {
        case "LanguageCourse":
          setSelectedCourseContent(languageProgramsContent);
          setCoreServiceCoverImage(languageServiceContentImg);
          break;
        case "HighSchoolCourse":
          setSelectedCourseContent(highSchoolProgramsContent);
          setCoreServiceCoverImage(highSchoolServiceContentImg);
          break;
        case "CollegeUniversityCourse":
          setSelectedCourseContent(collegeUniversityProgramsContent);
          setCoreServiceCoverImage(universityServiceContentImg);
          break;
        default:
          setSelectedCourseContent(null);
          setCoreServiceCoverImage(universityServiceContentImg);
      }
    }, 0);
  };

  return (
    <ContentStyle>
      <div className='HomePageContentDiv' id='Home' style={{ width: "100%", padding: "1em 3%", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Section id="Home">
          <div className='HomePageContentBanner' style={{ position: "relative", display: "flex", justifyContent: "center" }}>
            <img
              src={banner_img}
              alt="Study in Canada banner - Tổng hợp chương trình du học Canada"
              loading="lazy"
              style={{ width: "100%", borderRadius: "1rem" }}
            />
            <BannerButton className='banner_btn' onClick={() => MoveToSection('CoreServices')}>
              Tìm hiểu thêm
            </BannerButton>
          </div>
        </Section>
        <Section id="CoreServices">
          <div className='HomePageContentCoreServices'>
            <div className='HomePageContentCoreServicesTitle' style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <h1 style={{ color: red_primary, fontSize: "2.5em", lineHeight: "normal" }}>Các chương trình du học tại Canada</h1>
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", width: "100%" }}>
                <Row style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "1em 3%", flexWrap: "wrap" }}>
                  <Col xs={24} md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CoreServiceCard
                      title="Chương trình Trung học"
                      description="Chương trình trung học Canada cung cấp cho bạn nền giáo dục toàn diện và chất lượng cao, giúp bạn chuẩn bị cho việc học đại học hoặc cao đẳng. Chương trình học tập được thiết kế để phát triển tư duy phản biện, kỹ năng giải quyết vấn đề và khả năng giao tiếp của bạn...."
                      onClick={() => handleCourseClick("HighSchoolCourse")}
                    />
                  </Col>
                  <Col xs={24} md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CoreServiceCard
                      title="Chương trình học ngôn ngữ"
                      description="Canada là quốc gia đa văn hóa với hai ngôn ngữ chính thức là tiếng Anh và tiếng Pháp. Do đó, chương trình học ngôn ngữ tại Canada cung cấp đa dạng lựa chọn cho sinh viên quốc tế, giúp bạn trau dồi khả năng ngoại ngữ trong môi trường học tập lý tưởng..."
                      onClick={() => handleCourseClick("LanguageCourse")}
                    />
                  </Col>
                  <Col xs={24} md={8} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CoreServiceCard
                      title="Chương trình Cao đẳng và Đại học"
                      description="Canada có hệ thống giáo dục đại học và cao đẳng uy tín với nhiều trường đại học và cao đẳng hàng đầu thế giới. Các chương trình đào tạo tại đây đa dạng và phong phú, đáp ứng nhu cầu học tập và nghiên cứu của du học sinh quốc tế...."
                      onClick={() => handleCourseClick("CollegeUniversityCourse")}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Section>
        <Section id="ServicesInformation">
          <div className='HomePageContentCoreServicesContent' style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2rem 0" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "1em 3%", flexWrap: "wrap" }}>
              <Col xs={18} md={9} style={{ display: "flex", justifyContent: "center" }}>
                <img src={coreServiceCoverImage} alt="core service content" style={{ width: "90%" }} />
              </Col>
              <Col xs={24} md={12}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <h2 style={{ color: red_primary, fontSize: "2em", lineHeight: "normal" }}>{selectedCourseContent.title}</h2>
                  <div style={{ lineHeight: "normal", textAlign: "justify", color: "#555555", fontSize: "1rem" }}>
                    {selectedCourseContent.description}
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "1em" }}>
                    <Button className='coreservice_register_btn' style={{
                      height: "fit-content",
                      padding: "0.5rem 3rem",
                      fontSize: "1.5em",
                      borderRadius: "5px",
                      background: "linear-gradient(to right, #E31E25, #FF676C)",
                      color: "#fff",
                      border: "none",
                      cursor: "pointer",
                      boxShadow: "0 0 0.75rem #333",
                    }}>
                      Đăng ký ngay
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Section>
        <Section id="AboutUs">
          <div className='HomePageContentCeoTestimonial' style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "0 0", width: "100%" }}>
            <CeoTestimonialBgr>
              <Row style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "1em 0", flexWrap: "wrap" }}>
                <Col xs={14} sm={16} md={10} lg={8} style={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "100%" }}>
                  <Row style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "60%" }}>
                    <img src={ceo_avt} alt="CEO avatar" style={{ maxHeight: "70%", width: "auto", maxWidth: "100%" }} />
                    <div style={{ color: "white", fontSize: "2rem", fontWeight: "bold", lineHeight: "normal" }}>Huy Cường</div>
                  </Row>
                  <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "40%", lineHeight: "normal" }}>
                    <div style={{ fontSize: "1.5rem", lineHeight: "normal" }}>Tổng giám đốc</div>
                    <img src={company_name} alt="GoCAN company name visualization" style={{ height: "auto", width: "50%", marginLeft: "0.3rem", marginTop: "0.8rem", lineHeight: "normal" }} />
                  </Row>
                </Col>

                <Col xs={22} md={10} style={{ padding: "1em" }}>
                  <CeoTestimonialText>
                    <i>"goCAN tin rằng ai cũng có thể du học, miễn là bạn có ước mơ và quyết tâm chinh phục nó. Chúng tôi nỗ lực không ngừng trong phát triển giáo dục Việt Nam, đồng hành cùng cơ quan nhà nước trong phát triển nhân tài và nguồn lực quốc gia. Với đội ngũ nhiều kinh nghiệm trong lĩnh vực tư vấn giáo dục, goCAN cung cấp dịch vụ trọn gói cho những học sinh có nhu cầu học tập tại các nước có nền giáo dục phát triển như Canada, Mỹ, Úc, Châu âu, v.v."</i>
                  </CeoTestimonialText>
                </Col>
              </Row>
            </CeoTestimonialBgr>
          </div>

          <div className='HomePageContentCoreHighLightContent' style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "2rem 0", width: "100%" }}>
            <Row style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "1em 3%", flexWrap: "wrap" }}>
              <Col xs={24} md={12}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <h1 style={{ color: red_primary, fontSize: "2.5em", lineHeight: "normal" }}>{whyGoCANContent.title}</h1>
                  <div style={{ lineHeight: "normal", textAlign: "justify", color: "#555555", fontSize: "1rem" }}>
                    {whyGoCANContent.description}
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "1em" }}>
                    <Button className='consulting_registration_btn' style={{
                      height: "fit-content",
                      padding: "0.5rem 3rem",
                      fontSize: "1.5em",
                      width: "fit-content",
                      background: "linear-gradient(to right, #E31E25, #FF676C)",
                      color: "#fff",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "0.8rem",
                      boxShadow: "0 0 0.75rem #333",
                    }}>
                      <div style={{ width: "100%", display: "flex", flexWrap: "auto", whiteSpace: "normal", overflowWrap: "break-word", wordWrap: "break-word" }}>
                        Liên hệ ngay để được tư vấn miễn phí
                      </div>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} style={{ display: "flex", justifyContent: "center" }}>
                <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", padding: "1em" }}>
                  <Col xs={24} md={18} style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <HighLightCard title="100+" description="Trường đối tác" />
                    <HighLightCard title="200+" description="Đội ngũ cộng tác viên khắp các tỉnh bang" />
                  </Col>
                  <Col xs={24} md={18} style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <HighLightCard title="98%" description="Tỉ lệ thành công" />
                    <HighLightCard title="24/7" description="Chăm sóc khách hàng 24/7" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Section>
      </div>
    </ContentStyle>
  );
};

const HomePage = () => {
  const [surveyVisible, setSurveyVisible] = useState(true);
  const [activeLastSurveyVisible, setActiveLastSurveyVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSurveySubmit = (values) => {
    setSurveyVisible(false);
    setIsSubmitted(true);
    fetch(REGISTER_LAMBDA_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'x-api-key': apiKey,
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  const handleSurveyCancel = () => {
    setSurveyVisible(false);
  };

  const handleScroll = () => {
    if (isSubmitted) return;
    const scrollPosition = window.scrollY + window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;
    if (scrollPosition / pageHeight > 0.7) {
      if (!activeLastSurveyVisible) {
        setActiveLastSurveyVisible(true);
        setSurveyVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isSubmitted, activeLastSurveyVisible]);

  return (
    <LayoutStyle>
      <Helmet>
        <title>goCAN - Du học Canada an toàn, uy tín, đa dạng chương trình</title>
        <meta name="description" content="Hãy để goCAN đồng hành cùng bạn và gia đình trên chặng đường du học Canada" />
        <meta name="keywords" content="Canada, du học, định cư, học tập, tương lai, đúng đắn, nhà trường, gia đình, du học sinh" />
      </Helmet>
      <HomePageHeader />
      <HomePageContent />
      <HomePageFooter />
      <Survey
        visible={surveyVisible}
        onSubmit={handleSurveySubmit}
        onCancel={handleSurveyCancel}
      />
      <AffixSocialMedia />
    </LayoutStyle>
  );
};

export default HomePage;