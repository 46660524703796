import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
// import HomePage from './pages/HomePage';
import HomePage from './pages/homepage';
import { red_primary } from './styles/colors';
import './App.css';
import LandingPage from './pages/LandingPage';
import Leads from './pages/leads';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: red_primary,
          borderRadius: 2,
          colorBgContainer: '#fff',
        },
      }}
    >
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/admin/leads" element={<Leads />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/landingpage" element={<LandingPage />} />
          </Routes>
        </div>
      </Router>
    </ConfigProvider>
  );
}

export default App;
